import React from "react";
import styled from "styled-components";
//-----COMPONENTS-----
import {
  WidthBreakGrid,
  MainBreakpointGrid,
  PaddingBox,
} from "../components/globals";
import Layout from "../components/layout";
import GreyPanels from "../components/greyPanels";
import PageInfo from "../components/pageInfo";
import KittyCornerTiles from "../components/kittyCornerTiles";
//-----MATERIAL-----
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
//-----ASSETS-----
// import FrameConstruction1x from "../assets/wood-house-1x.png";
import FrameConstruction2x from "../assets/wood-house-2x.png";
// import FrameConstruction3x from "../assets/wood-house-3x.png";
// import MovingIn1x from "../assets/moving-to-new-home-1x.png";
import MovingIn2x from "../assets/fwdphotosforwebsite/worker-hole2.jpg";
// import MovingIn3x from "../assets/moving-to-new-home-3x.png";
// import Construction1x from "../assets/house-construction-1x.png";
import Construction2x from "../assets/house-construction-2x.png";
//import Construction3x from "../assets/house-construction-3x.png";

import {  Management } from "../data/pageDetails";

const StyledGreyPanels = styled(GreyPanels)`
  display: none;
  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      display: block;
    }
  `}
`;

const Img = styled(Grid)`
  background-image: url(${(props) => props.bg});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 290px;
  @media (min-width: 960px) {
    height: 66.4vh;
  }
  @media (max-height: 435px) {
    height: 66.4vh;
    min-height: 0px;
  }
`;

const QuoteContainer = styled(Box)`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 5em 0;
`;

const QuoteMarks = styled(Typography)`
  position: relative;
  padding: 0 2.5em;
  max-width: 95%;
  &::before,
  &::after {
    font-size: 4.625rem;
    position: absolute;
  }
  &::before {
    content: "“";
    transform: translate(-30px, -10px);
  }
  &::after {
    content: "“";
    transform: scale(-1) translate(20px, 5px);
    right: 0;
  }

  ${({ theme }) => `
    ${theme.breakpoints.up("md")} {
      max-width: 95%;
      text-align: right;
      &::before,
      &::after {
        font-size: 6.9375rem;
      }
      &::before {
        transform: translateX(-45px);
      }
      &::after {
        transform: scale(-1) translate(20px);
      }
    }
  `}
`;

const StyledButton = styled(Button)`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  font-weight: 700;
  text-transform: capitalize;
  background-color: none;
`;

const LookForPropContainer = styled(Box)`
  width: 100%;
  padding: 2.5em 0;
  @media (min-width: 960px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33.6vh;

    ${PaddingBox} {
      display: inline-block;
      text-align: ${(props) => (props.$mobile ? "left" : "right")};
    }
  }
`;

function SeeProjectsButton() {
  return (
    <>
      <Hidden mdUp>
        <StyledButton disableRipple>See Projects</StyledButton>
      </Hidden>
    </>
  );
}

function LookForPropCTA(props) {
  return (
    <LookForPropContainer {...props}>
      <PaddingBox>
        <Typography variant="h3" component={Box} pb={1}>
          looking for property?
        </Typography>
        <Typography variant="body2">We Can Help</Typography>
      </PaddingBox>
    </LookForPropContainer>
  );
}

function Quote() {
  return (
    <QuoteContainer>
      <QuoteMarks
        variant="body1"
        component={Box}
        fontSize={{ md: "1.625rem" }}
        lineHeight={{ md: "31px" }}
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Bibendum est
        ultricies integer quis. Iaculis urna id volutpat lacus laoreet. Mauris
        vitae ultricies leo integer malesuada. Ac odio tempor orci dapibus
        ultrices in. Egestas diam in arcu cursus euismod. Dictum fusce ut
        placerat orci nulla.
      </QuoteMarks>
    </QuoteContainer>
  );
}

export default () => {
  return (
    <Layout>
      <StyledGreyPanels />

      <Grid container item component={Box} position="relative" zIndex="1">
        <MainBreakpointGrid>
          <Img item bg={FrameConstruction2x} />
          <Hidden smDown>
            <LookForPropCTA />
          </Hidden>
        </MainBreakpointGrid>

        <MainBreakpointGrid>
          <PageInfo content={Management} highlightGetInTouch="true" />
        </MainBreakpointGrid>

        <Hidden mdUp>
          <WidthBreakGrid>
            <LookForPropCTA $mobile />
          </WidthBreakGrid>
        </Hidden>

        <WidthBreakGrid>
          <KittyCornerTiles
            tileOne={MovingIn2x}
            tileTwo={Construction2x}
            tileThree={<SeeProjectsButton />}
            bgColor="hsl(0, 0%, 89%)"
          />
        </WidthBreakGrid>

        <WidthBreakGrid>
          <Quote />
        </WidthBreakGrid>
      </Grid>
    </Layout>
  );
};
